@font-face {
    font-family: 'poiretone';
    src: url('/fonts/PoiretOne-Regular.ttf');
}

@font-face {
    font-family: 'robotoCustom';
    src: url('/fonts/RobotoMono-VariableFont_wght.ttf');
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.link-button {
    color: #fff;
    /* width: clamp(4ch, 50%, 10ch); */
}

.link-button:hover {
    color: aqua;
}

.link-button-white {
    color: aqua;
}

.link-button-white:hover {
    color: #0077c2;
}

.padding2 {
    padding: 2px;
}

.heading {
    font-size: "32px";
}